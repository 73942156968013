<template>
  <v-container class="mt-3">
    <v-row>
      <v-col md="12">
        <div class="black--text mx-1 text-body-2">
          {{ $t("home") }} <v-icon>mdi-chevron-right</v-icon> {{ $t("user") }}
          <v-icon>mdi-chevron-right</v-icon>
          <span class="primary--text"> {{ $t("new_users") }}</span>
        </div>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="py-6 px-4">
            <v-card-text>
              <v-row class="d-flex flex-column mb-2">
                <div class="d-flex justify-space-between align-center">
                  <div class="font-weight-medium text-caption text-uppercase">
                    {{ $t("add_remove_update") }}
                  </div>
                </div>
                <v-divider class="SimpleSolid"></v-divider>
              </v-row>

              <v-row class="d-flex flex-column">
                <div class="d-flex justify-space-between align-center mb-4">
                  <div class="black--text text-caption text-uppercase">
                    {{ $t("name") }}
                  </div>
                  <div style="width: 80%">
                    <whirelab-input
                      v-model="role.name"
                      :placeholder="$t('job_title')"
                      class="black--text text-caption"
                      required
                    />
                  </div>
                </div>

                <div class="d-flex justify-space-between align-center mb-4">
                  <div class="black--text text-caption text-uppercase">
                    {{ $t("permissions") }}
                  </div>
                  <div style="width: 80%">
                    <treeselect
                      v-model="role.permissions"
                      :multiple="true"
                      :options="permissionsVal"
                      :placeholder="$t('permissions')"
                      value-consists-of="LEAF_PRIORITY"
                      required
                      layout="filled"
                    />
                  </div>
                </div>

                <div class="d-flex justify-space-between mb-4">
                  <div class="black--text text-caption text-uppercase">
                    {{ $t("description") }}
                  </div>
                  <div style="width: 80%">
                    <whirelab-text-area
                      v-model="role.description"
                      :placeholder="$t('main_responsibilities')"
                      class="black--text text-caption"
                    />
                  </div>
                </div>

                <div class="text-right">
                  <v-btn
                    v-if="role.id"
                    color="primary"
                    style="background-color: #fff2f2"
                    class="mr-2"
                    text
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="cancel()"
                  >
                    <span class="black--text text-caption">
                      {{ $t("cancel") }}</span
                    >
                  </v-btn>
                  <v-btn
                    v-if="role.id"
                    color="primary"
                    style="background-color: #fff2f2"
                    class="mr-2"
                    text
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="deleteRoleById()"
                  >
                    <span class="black--text text-caption">
                      {{ $t("remove") }}</span
                    >
                  </v-btn>
                  <v-btn
                    v-if="role.id"
                    color="primary"
                    style="background-color: #fff2f2"
                    text
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="updateRoleById"
                  >
                    <span class="black--text text-caption">
                      {{ $t("update") }}</span
                    >
                  </v-btn>
                  <v-btn
                    v-else
                    color="primary"
                    style="background-color: #fff2f2"
                    text
                    :loading="isLoading"
                    :disabled="isLoading"
                    @click="saveRole"
                  >
                    <span class="black--text text-caption">
                      {{ $t("save") }}</span
                    >
                  </v-btn>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>

      <v-col cols="12" md="4" class="whirelab-positions-list">
        <template v-for="role in roles">
          <expansion-card-role
            class="mb-2"
            :role="role"
            :key="role.id"
            @clicked="selectedRole"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GET_PERMISSIONS from "../graphql/query/GetPermissions.gql";
import GET_ROLES from "../graphql/query/GetRoles.gql";
import CREATE_ROLE from "../graphql/mutation/CreateRole.gql";
import UPDATE_ROLE_BY_ID from "../graphql/mutation/UpdateRoleById.gql";
import DELETE_ROLE_BY_ID from "../graphql/mutation/DeleteRoleById.gql";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import WhirelabInput from "@/components/form/WhirelabInput.vue";
import WhirelabTextArea from "@/components/form/WhirelabTextArea.vue";
import ExpansionCardRole from "../components/ExpansionCardRole.vue";
export default {
  name: "ListRole",
  components: {
    Treeselect,
    WhirelabInput,
    WhirelabTextArea,
    ExpansionCardRole,
  },
  data: () => ({
    permissions: [],
    roles: [],
    role: {
      name: "",
      permissions: [],
      description: "",
      type: "ENTITY",
    },
    isLoading: false,
    valid: true,
  }),
  apollo: {
    permissions: {
      query: GET_PERMISSIONS,
      variables() {
        return {
          type: "ENTITY",
        };
      },
    },
    roles: {
      query: GET_ROLES,
      variables() {
        return {
          type: "ENTITY",
        };
      },
    },
  },
  computed: {
    permissionsVal() {
      if (this.permissions) {
        return [
          {
            id: this.getPermissionId("settings"),
            label: this.$t(this.$t("settings")),
          },
          // {
          //   id: this.getPermissionId("dashboard"),
          //   label: this.$t("dashboard"),
          // },
          {
            id: this.getPermissionId("internal_section"),
            label: this.$t("inner_section"),
          },
          {
            id: this.getPermissionId("external_section"),
            label: this.$t("external_section"),
          },
          {
            id: "positions",
            label: this.$t("positions"),
            children: [
              {
                id: this.getPermissionId("positions"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_positions"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_position"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_position"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_position"),
                label: this.$t("delete"),
              },
            ],
          },
          {
            id: "address",
            label: this.$t("address"),
            children: [
              {
                id: this.getPermissionId("address"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_address"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_address"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_address"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_address"),
                label: this.$t("delete"),
              },
            ],
          },
          {
            id: "access_level",
            label: this.$t("access_level"),
            children: [
              {
                id: this.getPermissionId("access_level"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_access_level"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_access_level"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_access_level"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_access_level"),
                label: this.$t("delete"),
              },
            ],
          },
          {
            id: "users",
            label: this.$t("users"),
            children: [
              {
                id: this.getPermissionId("users"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_users"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_user"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_user"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_user"),
                label: this.$t("delete"),
              },
            ],
          },
          {
            id: "employees",
            label: this.$t("employees"),
            children: [
              {
                id: this.getPermissionId("employees"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_employees"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_employee"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_employee"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_employee"),
                label: this.$t("delete"),
              },
            ],
          },
          {
            id: "departments",
            label: this.$t("departments"),
            children: [
              {
                id: this.getPermissionId("departments"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_departments"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_department"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_department"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_department"),
                label: this.$t("delete"),
              },
            ],
          },
          {
            id: "trainings",
            label: this.$t("trainings"),
            children: [
              {
                id: this.getPermissionId("trainings"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_trainings"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_training"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_training"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_training"),
                label: this.$t("delete"),
              },
            ],
          },
          {
            id: "trainees",
            label: this.$t("trainees"),
            children: [
              {
                id: this.getPermissionId("trainees"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_trainees"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_trainee"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_trainee"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_trainee"),
                label: this.$t("delete"),
              },
            ],
          },
          {
            id: "clients",
            label: this.$t("clients"),
            children: [
              {
                id: this.getPermissionId("clients"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_clients"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_client"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_client"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_client"),
                label: this.$t("delete"),
              },
            ],
          },
          {
            id: "external_trainings",
            label: this.$t("external_trainings"),
            children: [
              {
                id: this.getPermissionId("external_trainings"),
                label: this.$t("show"),
              },
              {
                id: this.getPermissionId("list_external_trainings"),
                label: this.$t("list"),
              },
              {
                id: this.getPermissionId("add_external_training"),
                label: this.$t("add"),
              },
              {
                id: this.getPermissionId("update_external_training"),
                label: this.$t("update"),
              },
              {
                id: this.getPermissionId("delete_external_training"),
                label: this.$t("delete"),
              },
            ],
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    cancel() {
      delete this.role.id;
      this.role.name = "";
      this.role.permissions = [];
      this.role.description = "";
      this.$refs.form.reset();
    },

    getPermissionId(key) {
      const findedIndex = this.permissions.findIndex((p) => {
        return p.name == key;
      });

      if (findedIndex >= 0) return this.permissions[findedIndex].id;
    },

    async saveRole() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          await this.$apollo.mutate({
            mutation: CREATE_ROLE,
            variables: {
              createRoleInput: {
                ...this.role,
              },
            },
            update: (store, { data: { createRole } }) => {
              const data = store.readQuery({ query: GET_ROLES });
              data.roles.push(createRole);
              store.writeQuery({ query: GET_ROLES, data });
              this.successfully = true;
              this.role.name = "";
              this.role.permissions = [];
              this.role.description = "";
              this.$refs.form.reset();
            },
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    async updateRoleById() {
      this.isLoading = true;
      delete this.role.__typename;
      const roleId = this.role.id;
      delete this.role.id;
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_ROLE_BY_ID,
          variables: {
            id: roleId,
            updateRoleInput: {
              ...this.role,
            },
          },
          update: (store, { data: { updateRoleById } }) => {
            const data = store.readQuery({ query: GET_ROLES });
            const findedRoleIndex = data.roles.findIndex(
              (findRole) => findRole.id === this.role.id
            );
            data.roles.splice(findedRoleIndex, 1, updateRoleById);
            store.writeQuery({ query: GET_ROLES, data });
            this.successfully = true;
            this.role.name = "";
            this.role.permissions = [];
            this.role.description = "";
            this.$refs.form.reset();
          },
        });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async deleteRoleById() {
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ROLE_BY_ID,
          variables: {
            id: this.role.id,
          },
          update: (store) => {
            const data = store.readQuery({ query: GET_ROLES });
            const findedRoleIndex = data.roles.findIndex(
              (findRole) => findRole.id === this.role.id
            );
            data.roles.splice(findedRoleIndex, 1);
            store.writeQuery({ query: GET_ROLES, data });
            this.successfully = true;
            delete this.role.id;
            this.role.name = "";
            this.role.permissions = [];
            this.role.description = "";
            this.$refs.form.reset();
          },
        });
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    selectedRole(roleId) {
      const role = this.roles.find((findRole) => findRole.id === roleId);
      console.log({ role });

      role.permissions = role.permissions.map((permission) =>
        permission.id ? permission.id : permission
      );

      this.role = Object.assign({}, role);
    },
  },
};
</script>