const feedbackView = () => import("./../views/Index.vue");
const FeedbackAdd = () => import("./../views/Add.vue");
const FeedbackEdit = () => import("./../views/Edit.vue");

export default [
  {
    path: "feedbacks",
    component: feedbackView,
  },
  {
    path: "feedbacks/add",
    component: FeedbackAdd,
  },
  {
    path: "feedbacks/edit/:id",
    component: FeedbackEdit,
    props: true,
  },
]