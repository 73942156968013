const ListPosition = () => import("../views/position.vue");

export default [
  {
    path: "positions/list",
    component: ListPosition,
    meta: { requiresAuth: true },
    name: "position",
  },
];
