<template>
  <v-app>
    <component :is="layout">
      <router-view :layout.sync="layout" />
    </component>
  </v-app>
</template>z

<script>
export default {
  name: "App",

  data: () => ({
    layout: `div`,
  }),
};
</script>

<style >
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");

html,
body {
  font-family: "Roboto", sans-serif;
}
#app {
  font-family: "Roboto", sans-serif;
}
</style>

<style lang="scss">
#app {
  background-color: #fff;
}

</style>
