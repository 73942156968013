export const feedback = {
  namespaced: true,
  state: {
    feedbacks: [],
  },
  mutations: {
    setFeedbacks(state, feedbacks) {
      state.feedbacks = feedbacks;
    },
    setFeedback(state, feedback) {
      state.feedbacks.push(feedback);
    }
  },
  actions: {
    setFeedbacks({ commit }, feedbacks) {
      commit("setFeedbacks", feedbacks);
    },
    setFeedback({ commit }, feedback) {
      commit("setFeedback", feedback);
    }
  },
  getters: {
    getFeedbacks: (state) => {
      return state.feedbacks;
    }
  }
}