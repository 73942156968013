export const employee = {
  namespaced: true,
  state: {
    employeeForm: {
      firstName: "",
      lastName: "",
      positionId: "",
      email: "",
      phoneNumber: "",
      contractType: "",
      mainFunctions: "",
      entryDate: null,
      moderator: false,
    },
  },
  mutations: {
    setEmployeeForm(state, employee) {
      state.employeeForm = employee;
    },
  },
  actions: {
    setEmployeeForm({ commit }, employee) {
      commit("setEmployeeForm", employee);
    },
  },
  getters: {
    getEmployeeForm: (state) => {
      return state.employeeForm;
    },
  },
};
