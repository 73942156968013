const AddAddress = () => import("../views/AddAddress.vue");

export default [
  {
    path: "address/list",
    component: AddAddress,
    meta: { requiresAuth: true },
    name: "address",
  },
];
