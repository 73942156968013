<template>
  <v-app-bar color="white" app>
    <v-app-bar-nav-icon @click="changeDrawer()"></v-app-bar-nav-icon>

    <v-text-field
      class="text-subtitle-1"
      style="max-width: 300px"
      append-icon="mdi-magnify"
      :placeholder="$t('search')"
      v-model="search"
      single-line
      hide-details
      dense
      outlined
    ></v-text-field>

    <v-spacer></v-spacer>

    <v-btn icon color="primary">
      <v-icon>mdi-information</v-icon>
    </v-btn>

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn text color v-on="on">
          <flag :iso="currentFlag" v-bind:squared="false" />
          <v-icon right color="primary">mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(entry, index) in whirelabLanguages"
          :key="`${index}010`"
          @click="changeLocale(entry)"
        >
          <v-list-item-title class="text-left">
            <div class="d-flex justify-start align-center">
              <div class="mr-2">
                <flag :iso="entry.flag" v-bind:squared="false" />
              </div>
              <div class="mt-1 text-subtitle-1">{{ entry.title }}</div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu left bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" color="primary" class="mr-2">
          <v-avatar size="22" item>
            <v-icon class="primary--text">mdi-bell</v-icon>
          </v-avatar>
        </v-btn>
      </template>
    </v-menu>

    <v-menu
      bottom
      origin="center center"
      transition="scale-transition"
      offset-y
      width="400px"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" color="primary">
          <v-avatar size="22" item>
            <v-icon class="primary--text">mdi-account-circle</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-card class="mx-auto text-left" v-if="currentUser">
        <v-list two-line>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="`/avatar.png`" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1">
                {{ currentUser.firstName }}
              </v-list-item-title>
              <v-list-item-subtitle class="">
                {{ currentUser.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item router to="/entity/profile">
            <v-list-item-action>
              <v-icon class="primary--text">mdi-account-circle</v-icon>
            </v-list-item-action>
            <v-list-item-title class="text-subtitle-1">{{
              $t("profile")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon class="primary--text">mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-title class="text-subtitle-1">{{
              $t("logout")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <v-menu
      v-if="currentUser && accessLevel(currentUser, 'setting')"
      left
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="primary" v-bind="attrs" v-on="on">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>

      <v-list>
        <template v-for="(setting, i) in settings">
          <v-list-item
            v-if="setting.show"
            route
            :key="i"
            :to="setting.route"
            :exact="setting.exact"
          >
            <v-list-item-action>
              <v-icon color="primary">{{ setting.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title class="text-body-1">{{
              setting.title
            }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { GC_USER_ID, GC_USER_TYPE, GC_AUTH_TOKEN } from "@/constants";
import { mapActions, mapGetters } from "vuex";
import GET_ME from "./../modules/auth/graphql/query/GetEmployee.gql";
import UPDATE_USER_BY_ID from "./../modules/entity/modules/user/graphql/mutation/UpdateUserById.gql";
import GET_TRAININGS from "@/graphql/query/GetTrainings.gql";
import accessLevelMixins from "@/mixins/access-level";

export default {
  name: "AppBar",
  mixins: [accessLevelMixins],
  data: () => ({
    currentFlag: "pt",
    drawer: true,
    search: undefined,
    filter: {
      positions: undefined,
    },
  }),
  apollo: {
    user: {
      query: GET_ME,
      fetchPolicy: "no-cache",
    },
    trainings: {
      query: GET_TRAININGS,
      variables() {
        return {
          filter: this.filter,
        };
      },
      result({ data }) {
        data.trainings.forEach(async (training) => {
          if (training.ongoingDate) {
            // this.manipulateStatus(training);
          }
        });
        this.setTrainingState(data.trainings);
      },
      fetchPolicy: "no-cache",
    },
  },
  watch: {
    search: function (val) {
      this.setSearch(val);
    },
    user: function (val) {
      this.setUserState(val);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    whirelabLanguages: function () {
      return [
        { flag: "gb", language: "en", title: "English" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },

    settings: function () {
      return [
        {
          icon: "mdi-domain",
          title: this.$t("positions"),
          route: "/entity/positions/list",
          exact: true,
          show: this.accessLevel(this.currentUser, "positions"),
        },
        {
          icon: "mdi-domain",
          title: this.$t("address"),
          route: "/entity/address/list",
          exact: true,
          show: this.accessLevel(this.currentUser, "address"),
        },
        {
          icon: "mdi-key-variant",
          title: this.$t("access_level"),
          route: "/entity/roles",
          exact: true,
          show: this.accessLevel(this.currentUser, "roles"),
        },
        {
          icon: "mdi-key-variant",
          title: "Feedback",
          route: "/entity/feedbacks",
          exact: true,
          show: true,
        },
        {
          icon: "mdi-account-group",
          title: this.$t("user"),
          route: "/users",
          exact: true,
          show: this.accessLevel(this.currentUser, "users"),
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
      setSearch: "auth/setSearch",
      setTrainingState: "training/setTrainings",
    }),

    async changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;
      localStorage.setItem("language", locale.language);
      localStorage.setItem("flag", locale.flag);
      this.updateLang(locale.language);
    },

    changeDrawer() {
      this.drawer = this.drawer ? false : true;
      this.$emit("changeDrawer", this.drawer);
    },

    async updateLang(lang) {
      if (this.currentUser) {
        await this.$apollo.mutate({
          mutation: UPDATE_USER_BY_ID,
          variables: {
            id: this.currentUser.id,
            updateUserInput: {
              lang: lang,
              type: this.currentUser.type,
            },
          },
        });
      }
    },

    logout() {
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_USER_TYPE);
      localStorage.removeItem(GC_AUTH_TOKEN);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
      this.$router.push("/login");
    },
  },
  created() {
    // window.addEventListener("beforeunload", this.leaving);
    if (localStorage.getItem("language") != null) {
      this.$i18n.locale = localStorage.getItem("language");
      this.currentFlag = localStorage.getItem("flag");
    } else {
      this.$i18n.locale = "pt";
      this.currentFlag = "pt";
    }
    this.updateLang(this.$i18n.locale);
  },
};
</script>