const ListEmployees = () => import("../views/ListEmployees.vue");
const AddOrEditEmployee = () => import("../views/AddOrEditEmployee.vue");
const EditEmployee = () => import("../views/EditEmployee.vue");
const ProfileEmployee = () => import("../views/ProfileEmployee.vue");
const ReadFile = () => import("../views/readFile.vue");

export default [
  {
    path: "employees/list",
    component: ListEmployees,
    meta: { requiresAuth: true },
    name: "employees",
  },
  {
    path: "employees/add",
    component: AddOrEditEmployee,
    meta: { requiresAuth: true },
    name: "employees.add",
    props: true,
  },
 
  {
    path: "employees/edit/:id",
    component: EditEmployee,
    meta: { requiresAuth: true },
    name: "employees.edit",
    props: true,
  },
  {
    path: "employees/show/:id",
    component: ProfileEmployee,
    meta: { requiresAuth: true },
    name: "employees.show",
    props: true,
  },
  {
    path: 'employees/document/:file',
    component: ReadFile,
    meta: { requiresAuth: true },
    name: 'Profile',
    props: true
  }
];
