<template>
  <v-textarea
    class="whirelab-input black--text text-body-2"
    :placeholder="placeholder"
    :value="value"
    :required="required"
    :rules="rules"
    :rows="rows"
    :row-height="rowHeight"
    :disabled="disabled"
    :readonly="readonly"
    rounded
    @keyup="updateValue($event.target.value)"
    style="padding-left: 20px"
  ></v-textarea>
</template>

<script>
export default {
  name: "WhirelabTextArea",
  props: ["placeholder", "value", "rows", "rowHeight", "required", "rules","disabled","readonly"],
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.whirelab-input {
  width: 100%;
  height: 150px;
  padding: 0px 0px;
  box-sizing: border-box;
  border: 1px solid #fff2f2;
  border-radius: 2px;
  background-color: #fff2f2;
  font-size: 16px;
  resize: none;
  color: rgba(0, 0, 0, 0.87);
}

.whirelab-input:focus {
  outline: none !important;
  border: 1px solid #f7a400;
  box-shadow: 0 0 3px #f7a400;
}
</style>