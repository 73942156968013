export default {
  methods: {
    accessLevel(currentUser, permission) {
      console.log({ currentUser });
      if (
        currentUser.role.name === "admin" ||
        currentUser.role.name === "administrator"
      ) {
        return true;
      }
      const index = currentUser.role.permissions.findIndex(
        (p) => p.name == permission
      );
      return index > -1;
    },
  },
};
