const Login = () => import("./../views/Login.vue");
const ResetPassword = () => import("./../views/ResetPassword.vue");
const RecoverAccount = () => import("./../views/RecoverAccount.vue");
const CreateEntity = () => import("./../views/CreateEntity.vue");

export default [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/recover/account",
    component: RecoverAccount,
  },
  {
    path: "/create-entity/:moduleId",
    component: CreateEntity,
    props: true,
  },
  {
    path: "/reset-password/:id",
    component: ResetPassword,
    props: true,
  },
];
