<template>
  <v-text-field
    class="whirelab-input black--text text-body-2"
    :value="value"
    :rules="rules"
    :append-icon="append_icon"
    :required="required"
    :readonly="readonly"
    :suffix="suffix"
    :type="type"
    rounded
    @keyup="updateValue($event.target.value)"
    :placeholder="placeholder"
  ></v-text-field>
</template>

<script>
export default {
  name: "WhirelabInput",

  props: ["placeholder", "value", "required", "rules", "append_icon", "suffix","type","readonly"],
  computed: {},
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.whirelab-input {
  width: 100%;
  height: 40px;
  padding: 4px 0px;
  box-sizing: border-box;
  border: 1px solid #fff2f2;
  border-radius: 2px;
  background-color: #fff2f2;
  font-size: 16px;
  resize: none;
  color: rgba(0, 0, 0, 0.87);
  padding-left: -1%;
}

.whirelab-input:focus {
  outline: none !important;
  border: 1px solid #f7a400;
  box-shadow: 0 0 3px #f7a400;
}
</style>