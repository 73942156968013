<template>
  <div >
    <navigation-drawer :propDrawer="drawer" />
    <app-bar @changeDrawer="changedDrawer" />
    <v-main>
      <slot />
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import NavigationDrawer from "../components/NavigationDrawer";
import AppBar from "../components/AppBar";
export default {
  name: "AppLayout",

  components: {
    NavigationDrawer,
    AppBar,
  },
  data: () => ({
    drawer: true,
  }),

  methods: {
    changedDrawer(val) {
      this.drawer = val;
    },
  },
  created() {},
};
</script>
