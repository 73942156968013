import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import 'vuetify/dist/vuetify.min.css'
// import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: "#F7A400", //141838
        secondary: "#141838", //F7A400
        navbar: "#FFFFFF",
        error: "#b71c1c",
        info: "#000",
        rhprimary: "#fff2f2",
      },
    },
  },
});
