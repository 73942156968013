import Dashboard from "../views/Dashboard.vue";
import employeeRoute from "./../modules/employee/router";
import departmentRoute from "./../modules/department/router";
import dashboardRoute from "./../modules/dashboard/router";
import trainingRoute from "./../modules/training/router";
import activityRoute from "./../modules/activity/router";
import meetingRoute from "./../modules/meeting/router";
import userRoute from "../modules/user/router";
import roleRoute from "../modules/roles/router";
import positionRoute from "../modules/position/router";
import addressRoute from "../modules/address/router";
import traineesRoute from "../modules/external-module/trainees/router";
import clientsRoute from "../modules/external-module/clients/router";
import extTrainingsRoute from "../modules/external-module/training/router";
import reportRoute from "../modules/report/router";
import profileRoute from "../modules/profile/router";
import feedbackRoute from "../modules/feedback/router";

export default [
  {
    path: "/entity",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      { path: "", redirect: { name: "dashboard" } },
      ...dashboardRoute,
      ...activityRoute,
      ...departmentRoute,
      ...employeeRoute,
      ...meetingRoute,
      ...trainingRoute,
      ...userRoute,
      ...positionRoute,
      ...addressRoute,
      ...traineesRoute,
      ...clientsRoute,
      ...extTrainingsRoute,
      ...roleRoute,
      ...reportRoute,
      ...profileRoute,
      ...feedbackRoute,
    ],
  },
];
