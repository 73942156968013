export const training = {
  namespaced: true,
  state: {
    trainings: [],
    trainingForm: {
      title: "",
      ongoingDate: null,
      ongoingTime: "",
      trainingType: "",
      guests: [],
      topics: "",
      positions: [],
      moderators: [],
      departments: [],
      members: undefined,
      predecessor: "",
      costs: [],
      activities: [],
      files: [],
      digitalAddress: "",
      type : ""
    },
  },
  mutations: {
    setTrainings(state, trainings) {
      state.trainings = trainings;
    },
    setTrainigForm(state, training) {
      state.trainingForm = training;
    },
  },
  actions: {
    setTrainings({ commit }, trainings) {
      commit("setTrainings", trainings);
    },
    setTrainigForm({ commit }, training) {
      commit("setTrainigForm", training);
    },
  },
  getters: {
    getTrainings: (state) =>  {
      return state.trainings
    },
    getTrainingForm: (state) => {
      return state.trainingForm;
    },
  },
};
